var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "channel-selector-container",
    style: _vm.cssVars
  }, [_c('div', {
    staticClass: "selector-options"
  }, _vm._l(_vm.channels, function (channel, index) {
    return _c('div', {
      key: channel.name,
      staticClass: "channel-option",
      class: {
        'checked': channel.enabled
      },
      on: {
        "click": function click($event) {
          return _vm.toggleChannel(index);
        }
      }
    }, [_c('div', {
      staticClass: "channel-toggle-area",
      class: channel.label
    }, [_c('div', {
      staticClass: "channel-toggle-icon"
    })]), _c('label', {
      attrs: {
        "for": channel.label
      }
    }, [_c('div', {
      staticClass: "channel-icon",
      class: channel.label
    }, [_c('svg-loader', {
      attrs: {
        "src": "/img/channels/".concat(channel.label, ".svg"),
        "color": "#fff"
      }
    })], 1), _c('span', [_vm._v(_vm._s(channel.name))])])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }