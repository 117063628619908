var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal-structure', {
    staticClass: "chart-colors-selector",
    attrs: {
      "on-click": function onClick() {
        return _vm.setChartColors();
      },
      "on-close": _vm.onCloseModal,
      "bot-color": _vm.getterSelectedBot.botColor,
      "is-loading": _vm.isLoading,
      "width": "594",
      "title": _vm.$t('analytic.chartColorsSelector.title'),
      "button-text": _vm.$t('analytic.chartColorsSelector.textButton')
    },
    on: {
      "callback": function callback($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('v-layout', {
    attrs: {
      "mb-6": "",
      "mt-8": ""
    }
  }, [_c('colors-palette', {
    attrs: {
      "multi-selector": "",
      "chart-ranking-list": _vm.chartColor
    },
    on: {
      "switchColor": function switchColor(data) {
        return _vm.switchRankColor(data);
      }
    }
  })], 1), _c('v-layout', {
    attrs: {
      "align-center": "",
      "justify-center": ""
    }
  }, [_c('v-btn', {
    class: {
      'disabled': _vm.isDefaultColorsConfig
    },
    attrs: {
      "text": "",
      "outlined": "",
      "ripple": false
    },
    on: {
      "click": function click($event) {
        return _vm.resetInitialColors();
      }
    }
  }, [_c('span', {
    staticClass: "reset-initial-colors"
  }, [_vm._v(_vm._s(_vm.$t('analytic.chartColorsSelector.reset')))])])], 1), _c('v-layout', {
    attrs: {
      "align-center": "",
      "justify-center": "",
      "my-2": ""
    }
  }, [_c('v-checkbox', {
    attrs: {
      "color": _vm.getterSelectedBot.botColor,
      "label": _vm.$t('analytic.chartColorsSelector.applyAll')
    },
    model: {
      value: _vm.applyColorsToAllCharts,
      callback: function callback($$v) {
        _vm.applyColorsToAllCharts = $$v;
      },
      expression: "applyColorsToAllCharts"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }