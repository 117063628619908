var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-analytic internal-page"
  }, [_vm.isLoading ? _c('loading', {
    staticClass: "loading"
  }) : _vm._e(), _c('section', {
    staticClass: "internal-generic-header"
  }, [_c('div', {
    staticClass: "search-header"
  }, [_c('div', {
    staticClass: "container-title"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t('analytic.title')))])]), _c('div', {
    staticClass: "container-search"
  }, [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.auto",
      value: {
        content: _vm.$t('analytic.tooltip.analysisDisplays'),
        offset: 10
      },
      expression: "{ content: $t('analytic.tooltip.analysisDisplays'), offset: 10 }",
      modifiers: {
        "auto": true
      }
    }],
    staticClass: "btn-question"
  }, [_c('svgLoader', {
    attrs: {
      "src": "/img/icon-question.svg",
      "color": "#000"
    }
  })], 1)])]), _c('v-row', [_c('v-col', {
    class: {
      'd-flex align-items align-center': _vm.checkFiltersInView()
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-2 text-select-period"
  }, [_vm._v(" " + _vm._s(_vm.$t('analytic.selectPeriod')) + " ")]), _c('customDatePicker', {
    attrs: {
      "is-loading": _vm.isLoading,
      "date-to-set": _vm.datePickerData
    },
    on: {
      "callback": function callback(data) {
        return _vm.datePickerData = data;
      }
    }
  })], 1), _c('v-col', {
    class: {
      'd-flex align-items align-center': _vm.checkFiltersInView()
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-2 text-select-period"
  }, [_vm._v(" " + _vm._s(_vm.$t('analytic.selectDataType')) + " ")]), _c('p-select', {
    staticStyle: {
      "width": "250px"
    },
    attrs: {
      "item-text": "title",
      "item-value": "dataType",
      "items": _vm.defaultCharts,
      "height": 28,
      "set-selected": _vm.graphicSelected.dataType,
      "disabled": _vm.isLoading
    },
    on: {
      "selected": _vm.setSelectedCondition
    }
  })], 1), _vm.graphicSelected.dataType === 'total_interactions' ? _c('v-col', {
    class: {
      'd-flex align-items align-center': _vm.checkFiltersInView()
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-2 text-select-period"
  }, [_vm._v(" " + _vm._s(_vm.$t('analytic.filterBy')) + " ")]), _c('p-select', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "item-text": "name",
      "item-value": "id",
      "items": _vm.translateFilter(_vm.graphicSelected.filterIntentions),
      "height": 28,
      "set-selected": 1,
      "disabled": _vm.isLoading
    },
    on: {
      "selected": _vm.setSelectedListIterations
    }
  })], 1) : _vm._e(), _vm.graphicSelected.filter && _vm.isFilterChannel ? _c('v-col', {
    class: {
      'd-flex align-items align-center': _vm.checkFiltersInView()
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-2 text-select-period"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.graphicSelected.nameFilter)) + ": ")]), _c('p-select', {
    key: _vm.graphicSelected.dataType,
    staticStyle: {
      "max-width": "230px"
    },
    attrs: {
      "item-text": "name",
      "item-value": "id",
      "items": _vm.translateFilter(_vm.graphicSelected.filter),
      "height": 28,
      "set-selected": _vm.graphicSelected.default,
      "disabled": _vm.isLoading,
      "mult-selected": _vm.graphicSelected.multSelected,
      "mult-selected-fast": true
    },
    on: {
      "selected": _vm.setSelectedListLength
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "container-actions-header"
  }, [_c('div', {
    staticClass: "container-select-info"
  }, [_c('v-btn', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.bottom",
      value: {
        content: _vm.$t('analytic.tooltip.customizeChartColors'),
        offset: 10
      },
      expression: "{ content: $t('analytic.tooltip.customizeChartColors'), offset: 10 }",
      modifiers: {
        "bottom": true
      }
    }],
    staticClass: "chart-colors-selector-button",
    staticStyle: {
      "top": "-47px"
    },
    attrs: {
      "fab": "",
      "small": "",
      "outlined": "",
      "color": "#333",
      "absolute": "",
      "ripple": false
    },
    on: {
      "click": function click($event) {
        _vm.chartColorsSelectorModal = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#333"
    }
  }, [_vm._v(" mdi-palette ")])], 1), _vm.chartColorsSelectorModal ? _c('chart-colors-selector', {
    attrs: {
      "default-colors": _vm.chartColorConfig
    },
    on: {
      "change": _vm.saveChartColor,
      "close": function close($event) {
        _vm.chartColorsSelectorModal = false;
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "legend"
  }, [_c('span', {
    staticClass: "legend-text",
    domProps: {
      "innerHTML": _vm._s(_vm.$t(_vm.graphicSelected.description))
    }
  })])])], 1), _c('section', {
    staticClass: "wrapper-analytic"
  }, [_vm.notSeries && !_vm.newGraphics && !_vm.isLoading ? _c('section', [_c('span', {
    staticClass: "text mt-16"
  }, [_vm._v(_vm._s(_vm.$t('analytic.noData')))])]) : _vm._e(), (!_vm.notSeries || _vm.newGraphics) && !_vm.isLoading ? _c('section', [!_vm.newGraphics ? _c('div', {
    staticClass: "charts-content"
  }, [_vm._l(_vm.graphics, function (chart, index) {
    return _c('v-flex', {
      key: index,
      staticClass: "chart-container",
      attrs: {
        "row": "",
        "justify-space-around": "",
        "align-center": ""
      }
    }, [_c('p-chart', {
      attrs: {
        "type": "area",
        "width": 600,
        "header": chart.header,
        "series": chart.series,
        "labels": chart.chartOptions.xaxis.categories,
        "colors": [_vm.chartColorConfig[index]]
      }
    })], 1);
  }), Object.keys(_vm.graphicsTotal).length && _vm.graphicsTotal.chartOptions ? _c('v-flex', {
    staticClass: "chart-container",
    attrs: {
      "row": "",
      "justify-space-around": "",
      "align-center": ""
    }
  }, [_c('p-chart', {
    attrs: {
      "type": "donut",
      "width": 600,
      "is-total": _vm.graphicsTotal.isTotal,
      "header": _vm.graphicsTotal.header,
      "series": _vm.graphicsTotal.series,
      "labels": _vm.graphicsTotal.chartOptions.labels,
      "colors": _vm.chartColorConfig
    }
  })], 1) : _vm._e()], 2) : _c('div', {
    staticClass: "charts-content"
  }, _vm._l(_vm.graphics, function (chart, index) {
    return _c('p-chart', {
      key: index,
      attrs: {
        "header": chart.header,
        "type": chart.type,
        "horizontal": chart.horizontal,
        "width": chart.width,
        "series": chart.series,
        "labels": chart.labels,
        "colors": _vm.chartColorConfig,
        "legends": chart.legends,
        "is-total": chart.isTotal
      }
    });
  }), 1)]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }