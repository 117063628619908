import { mapGetters } from 'vuex'
import { isEqual, cloneDeep } from 'lodash'
import { MaxColors } from '@/helper/colorsPalette'
import ColorsPalette from '@/components/colors-palette/index.vue'
import ModalStructure from '@/components/modals/modal-structure/modalStructure.vue'

export default {
  name: 'ChartColorsSelector',
  components: {
    ColorsPalette,
    ModalStructure
  },
  props: {
    defaultColors: {
      type: Array,
      default: MaxColors.slice(0, 20),
      required: false
    }
  },
  data: () => ({
    isLoading: false,
    onCloseModal: false,
    applyColorsToAllCharts: false,
    chartColor: []
  }),
  computed: {
    ...mapGetters(['getterSelectedBot']),
    isDefaultColorsConfig () {
      const defaultColorsConfig = isEqual(this.chartColor, MaxColors.slice(0, 20))
      return defaultColorsConfig
    }
  },
  mounted () {
    this.setDefaultColors()
  },
  methods: {
    setChartColors () {
      const data = {
        colors: this.chartColor,
        applyColorsToAllCharts: this.applyColorsToAllCharts
      }
      this.$emit('change', data)
      this.onCloseModal = true
    },
    closeModal () {
      this.$emit('close')
    },
    switchRankColor (data) {
      if (data.selected) {
        this.$set(this.chartColor, data.selected.rankPosition, data.selected.color)
      }
      this.$set(this.chartColor, data.switched.rankPosition, data.switched.color)
    },
    resetInitialColors () {
      this.chartColor = MaxColors.slice(0, 20)
    },
    setDefaultColors () {
      this.chartColor = cloneDeep(this.defaultColors)
    }
  }
}
