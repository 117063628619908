import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { cloneDeep, orderBy } from 'lodash'
import { MaxColors } from '@/helper/colorsPalette'
import { DefaultCharts } from '@/helper/defaultCharts'
import Loading from '@/components/loading/index.vue'
import {
  SvgLoader,
  CustomDatePicker,
  ExportAndPrinting,
  SelectMenu
} from '@/components'
import ChannelSelector from '@/components/channel-selector/index.vue'
import ChartColorsSelector from '@/components/modals/chart-colors-selector/chartColorsSelector.vue'

export default {
  components: {
    SvgLoader,
    CustomDatePicker,
    ExportAndPrinting,
    SelectMenu,
    Loading,
    ChannelSelector,
    ChartColorsSelector
  },
  data () {
    return {
      datePickerData: { startDate: new Date() },
      graphics: [],
      graphicsTotal: {},
      graphicSelected: {},
      listSelected: 1,
      listMultSelected: [1],
      colors: [
        '#007bff',
        '#ffc107',
        '#fd7e14',
        '#6f42c1',
        '#dc3545',
        '#28a745',
        '#17a2b8',
        '#20c997',
        '#e83e8c',
        '#6610f2',
        '#ed7555',
        '#4b71f0'
      ],
      infoGraphics: [],
      isLoading: false,
      isInitial: true,
      notSeries: false,
      openChartColorsSelector: false,
      chartColorsSelectorModal: false,
      selectedChannels: [],
      chartColorConfig: MaxColors.slice(0, 20),
      newGraphics: false,
      width: 1000,
      isFilterChannel: false,
      auxGraphicsFilter: [],
      context: '',
      hasIncomingEventsGraphic: false
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot',
      'getterAllInteractionChannel',
      'getterAllAverageInteractionChannel',
      'getterAllUserPerDay',
      'getterAllUsersChannel',
      'getterAllUsersIntent',
      'getterAllIDK',
      '$getterFormatDate'
    ]),
    defaultCharts () {
      const graphicsTranslate = cloneDeep(DefaultCharts)
      graphicsTranslate.forEach(element => {
        element.title = this.$t(element.title)
      })
      return orderBy(graphicsTranslate, ['title', 'asc'])
    }
  },
  watch: {
    datePickerData () {
      this.isLoading = true
      this.isInitial = true
      this.selectAnalytic()
    }
  },
  created () {
    [this.graphicSelected] = this.defaultCharts
    this.isLoading = true
    this.width = document.scrollingElement.offsetWidth
    this.setDefaultColors()
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.checkSizePage()
    })
    this.getChartColorConfigCache(this.graphicSelected.dataType)
  },
  methods: {
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    ...mapActions([
      'actionAnalyticUsageMetrics',
      'actionGetChartData',
      'actionAnalyticPopularIntentions',
      'actionAnalyticPopularExpressions',
      'actionAnalyticBotActiveTime',
      'actionAnalyticUsersInteractedBot',
      'actionAnalyticTotalInteractions',
      'actionAnalyticLowAssertivenessExpressions',
      'actionAnalyticUnknownExpressions'
    ]),
    checkSizePage () {
      this.width = window.innerWidth
    },
    checkFiltersInView () {
      if (this.graphicSelected.dataType === 'total_interactions') {
        return this.width > 1751
      }
      return this.width > (this.graphicSelected.filter ? 1451 : 1095)
    },
    setSelectedCondition (value) {
      if (!this.isLoading) {
        this.isLoading = true
        this.graphicSelected = this.defaultCharts.find(item => item.dataType === value)
        this.clearAll()
        this.selectAnalytic()
      }
    },
    clearAll () {
      this.listSelected = 1
      this.listMultSelected = [1]
      this.auxGraphicsFilter = []
      this.isFilterChannel = false
    },
    setSelectedListLength (value) {
      if (!this.isLoading) {
        if (this.graphicSelected.multSelected) {
          this.listMultSelected = value
        } else {
          this.listSelected = value
        }
        if (this.graphicSelected.dataType === 'users_interacted_bot') {
          const time = setTimeout(() => {
            this.isLoading = true
            this.getAnalyticUsersInteractedBot(true)
            clearTimeout(time)
          }, 100)
        } else if (this.graphicSelected.dataType === 'total_interactions') {
          const time = setTimeout(() => {
            this.isLoading = true
            this.getAnalyticTotalInteractions(true)
            clearTimeout(time)
          }, 100)
        } else {
          this.isLoading = true
          this.selectAnalytic()
        }
      }
    },
    setSelectedListIterations (value) {
      if (!this.isLoading) {
        this.clearAll()
        this.graphicSelected.filter = [{ id: 1, name: this.$t('analytic.all') }]
        this.isLoading = true
        switch (value) {
          case 2:
            this.context = 'bot'
            break
          case 3:
            this.context = 'talk'
            break
          default:
            this.context = ''
            break
        }
        this.getAnalyticTotalInteractions()
      }
    },
    getGraphics () {
      if (!Object.prototype.hasOwnProperty.call(this.datePickerData, 'startDate') || !Object.prototype.hasOwnProperty.call(this.datePickerData, 'endDate')) {
        return
      }

      this.isLoading = true
      const params = {
        idBot: this.getterSelectedBot.idBot,
        startDate: this.datePickerData.startDate,
        endDate: this.datePickerData.endDate
      }
      this.actionAnalyticUsageMetrics(params)
        .then(res => {
          if (res && res.data && res.data.data) {
            this.checkInfoCall(res.data.data)
          } else {
            this.notSeries = true
          }
        })
        .catch(() => {
          this.notSeries = true
          this.addToast({
            text: this.$t('analytic.toast.errorFetching'),
            type: 'danger'
          })
        })
        .finally(() => {
          this.isInitial = false
          this.isLoading = false
        })
    },
    checkInfoCall (data) {
      let hasSeries = false
      Object.entries(data).forEach(item => {
        if (item[1].series.length) {
          hasSeries = true
        }
      })
      if (hasSeries) {
        this.infoGraphics = []
        this.infoGraphics = data
        this.notSeries = false
        this.createdGraphic()
      } else {
        this.infoGraphics = []
        this.notSeries = true
      }
    },
    createdGraphic () {
      if (this.infoGraphics[this.graphicSelected.dataType]) {
        this.graphics = []
        this.graphicsTotal = {}
        const label = []
        this.infoGraphics[this.graphicSelected.dataType].labels.forEach(item => {
          label.push(moment(item).format(this.$getterFormatDate))
        })
        let isData = false
        this.infoGraphics[this.graphicSelected.dataType].series.forEach((item, index) => {
          item.data.forEach(data => {
            if (!isData && data) {
              isData = true
            }
          })
          this.graphics.push({
            series: [
              {
                name: item.name,
                data: item.data
              }
            ],
            name: item.name,
            chartOptions: this.charOptionsLine(label, this.colors[index]),
            logo: this.logoChannel(item.name),
            header: {
              title: item.name,
              customIcon: this.logoChannel(item.name)
            }
          })
        })
        const time = setTimeout(() => {
          if (isData) {
            this.createdGraphicTotal()
            this.getSuccess()
            this.notSeries = false
          } else {
            this.notSeries = true
            this.getWarning()
          }
          clearTimeout(time)
        }, 300)
      } else {
        this.getWarning()
      }
    },
    charOptionsLine (label, color) {
      return {
        chart: {
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: [color],
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        grid: {
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        fill: {
          gradient: {
            enabled: true,
            opacityFrom: 0.55,
            opacityTo: 0
          }
        },
        markers: {
          size: 4,
          colors: ['#ffffff'],
          strokeColor: '#dddddd',
          strokeWidth: 2
        },
        xaxis: {
          type: 'date',
          categories: label,
          labels: {
            rotate: -45
          }
        },
        tooltip: {
          theme: 'dark'
        }
      }
    },
    createdGraphicTotal () {
      const serieDonut = []
      const label = []
      const reducer = (accumulator, currentValue) => accumulator + currentValue
      this.graphics.forEach(item => {
        serieDonut.push(item.series[0].data.reduce(reducer))
        label.push(item.series[0].name)
      })
      this.graphicsTotal = {
        series: serieDonut,
        isTotal: this.hasIncomingEventsGraphic,
        chartOptions: this.charOptionsTotal(label),
        header: {
          title: this.hasIncomingEventsGraphic ? this.$t('analytic.totalEvasions') : this.$t('analytic.totalInteractions')
        }
      }
    },
    charOptionsTotal (label) {
      return {
        labels: label,
        colors: this.colors,
        responsive: [{
          breakpoint: 680,
          options: {
            chart: {
              width: 400
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },
    logoChannel (name) {
      const normalizeChannelName = name.replace(/([^\w]+|\s+)/g, '').toLowerCase()
      const basePath = '/img/channels'

      switch (normalizeChannelName) {
        case 'peoplechat':
          return `${basePath}/peoplechat.svg`
        case 'workplace':
          return `${basePath}/workplace.svg`
        case 'email':
        case 'chatapiemail':
          return `${basePath}/email.svg`
        case 'cortana':
        case 'chatapicortana':
          return `${basePath}/cortana.svg`
        case 'telegram':
        case 'chatapitelegram':
          return `${basePath}/telegram.svg`
        case 'twilio':
        case 'twillio':
        case 'chatapitwilio':
        case 'chatapitwillio':
          return `${basePath}/twilio.svg`
        case 'facebook':
        case 'facebookpages':
        case 'chatapifacebook':
          return `${basePath}/facebook.svg`
        case 'skype':
        case 'skypebusiness':
        case 'chatapiskype':
          return `${basePath}/skype.svg`
        case 'msteams':
        case 'microsoftteams':
        case 'chatapimsteams':
          return `${basePath}/teams.svg`
        case 'whatsapp':
        case 'chatapiwhatsapp':
          return `${basePath}/whatsapp.svg`
        default:
          return `${basePath}/default.svg`
      }
    },
    getChartData () {
      const data = {
        chartName: this.graphicSelected.dataType,
        botId: this.getterSelectedBot.idBot,
        startDate: this.datePickerData.startDate,
        endDate: this.datePickerData.endDate
      }

      this.actionGetChartData(data)
        .catch(err => console.error(err))
    },
    setDefaultColors () {
      const data = {}
      if (!localStorage.getItem('charts_colors_config')) {
        DefaultCharts.forEach(chart => {
          data[chart.dataType] = this.chartColorConfig
        })

        localStorage.setItem('charts_colors_config', JSON.stringify(data))
      }
    },
    saveChartColor (colorsConfig) {
      let data = {}

      if (colorsConfig.applyColorsToAllCharts) {
        DefaultCharts.forEach(chart => {
          data[chart.dataType] = colorsConfig.colors
        })
      } else {
        const chartsColorsConfig = localStorage.getItem('charts_colors_config')

        if (chartsColorsConfig) {
          data = JSON.parse(chartsColorsConfig)
        }

        data[this.graphicSelected.dataType] = colorsConfig.colors
      }

      const updatedData = JSON.stringify(data)
      localStorage.setItem('charts_colors_config', updatedData)
      this.chartColorConfig = colorsConfig.colors
    },
    getChartColorConfigCache (chartName) {
      const chartsColorsConfig = localStorage.getItem('charts_colors_config')

      if (chartsColorsConfig) {
        const chartColorCacheConfig = JSON.parse(chartsColorsConfig)[chartName]

        if (chartColorCacheConfig) {
          this.chartColorConfig = chartColorCacheConfig
        }
      }
    },
    setSelectedQtdData (item) {
      this.qtdData = item
      this.selectAnalytic()
    },
    selectAnalytic () {
      this.getChartColorConfigCache(this.graphicSelected.dataType)
      switch (this.graphicSelected.dataType) {
        case 'popular_intentions':
          this.isFilterChannel = true
          this.newGraphics = true
          this.getAnalyticPopularIntentions()
          break
        case 'popular_expressions':
          this.isFilterChannel = true
          this.newGraphics = true
          this.getAnalyticPopularExpressions()
          break
        case 'bot_uptime':
          this.newGraphics = true
          this.getAnalyticBotActiveTime()
          break
        case 'users_interacted_bot':
          this.listMultSelected = [1]
          this.graphicSelected.filter = [{ id: 1, name: this.$t('analytic.all') }]
          this.newGraphics = true
          this.getAnalyticUsersInteractedBot()
          break
        case 'total_interactions':
          this.listMultSelected = [1]
          this.graphicSelected.filter = [{ id: 1, name: this.$t('analytic.all') }]
          this.newGraphics = true
          this.getAnalyticTotalInteractions()
          break
        case 'low_assertiveness':
          this.newGraphics = true
          this.getAnalyticLowAssertiveness()
          break
        case 'unknown_expressions':
          this.newGraphics = true
          this.getAnalyticUnknownExpressions()
          break
        default:
          this.hasIncomingEventsGraphic = this.graphicSelected.dataType === 'incoming_events'
          this.newGraphics = false
          if (this.isInitial) {
            this.getGraphics()
          } else {
            this.isLoading = true
            this.createdGraphic()
          }
          break
      }
    },
    mountParams () {
      return {
        botId: this.getterSelectedBot.idBot,
        ...this.datePickerData,
        page: 1,
        pageCount: this.listSelected * 10,
        ordinationType: 'desc',
        ordinationField: 'count'
      }
    },
    getAnalyticPopularIntentions () {
      this.actionAnalyticPopularIntentions(this.mountParams())
        .then(response => {
          this.notSeries = !response.series.length
          this.newGraphics = !!response.series.length
          const legends = [
            {
              text: this.$t('analytic.totalIntentionsRegisteredBot'),
              value: response.amount_intentions
            }
          ]
          this.mountedGraphics(response, legends, true, this.customLabelsSeries(response))
          // eslint-disable-next-line no-unused-expressions
          response.series.length ? this.getSuccess() : this.getWarning()
        })
        .catch(() => {
          this.getError()
        })
    },
    getAnalyticPopularExpressions () {
      this.actionAnalyticPopularExpressions(this.mountParams())
        .then(response => {
          this.notSeries = !response.series.length
          this.newGraphics = !!response.series.length
          this.mountedGraphics(response, [], true, this.customLabelsSeries(response))
          // eslint-disable-next-line no-unused-expressions
          response.series.length ? this.getSuccess() : this.getWarning()
        })
        .catch(() => {
          this.getError()
        })
    },
    getAnalyticBotActiveTime () {
      const params = this.mountParams()
      params.ordinationField = 'date'
      params.ordinationType = 'asc'

      this.actionAnalyticBotActiveTime(params)
        .then(response => {
          const legends = []
          const labels = []
          const series = []
          let isData = false
          response.labels[0].values.forEach(item => {
            if (item.includes('_')) {
              const dates = item.split('_')
              labels.push(`${moment(dates[0]).format('MM/YYYY')}`)
            } else {
              labels.push(moment(item).format(this.$getterFormatDate))
            }
          })
          response.series.forEach(item => {
            if (item.name === 'active_time') {
              item.name = this.$t('analytic.withInteraction')
              legends.push(
                {
                  text: this.$t('analytic.totalInteractivityBot'),
                  value: item.total[0].toFixed(0)
                }
              )
            } else {
              item.name = this.$t('analytic.noInteraction')
              legends.push(
                {
                  text: this.$t('analytic.totalNoInteractivityBot'),
                  value: item.total[0].toFixed(0)
                }
              )
            }
            if (!isData && item.total[0]) {
              isData = true
            }
            const values = []
            item.data[0].values.forEach(hours => {
              values.push(hours.toFixed(0))
            })
            series.push({ data: [{ values }], name: item.name, total: [+item.total[0].toFixed(0)] })
          })
          if (isData) {
            this.mountedGraphics({ series, labels: response.labels }, [], true, { listLabels: labels })
            this.getSuccess()
          } else {
            this.notSeries = true
            this.newGraphics = false
            this.getWarning()
          }
        })
        .catch(() => {
          this.getError()
        })
    },
    getAnalyticUsersInteractedBot (isFilterChannel) {
      if (isFilterChannel) {
        this.filterChannels(this.$t('analytic.totalUsersInteractedBot'))
      } else {
        this.isFilterChannel = false
        const params = this.mountParams()
        params.pageCount = 20
        params.ordinationField = 'date'
        params.ordinationType = 'asc'

        this.actionAnalyticUsersInteractedBot(params)
          .then(response => {
            this.auxGraphicsFilter = response
            this.createdFilterChannels()
            this.filterChannels(this.$t('analytic.totalUsersInteractedBot'))
          })
          .catch(() => {
            this.getError()
          })
        const time = setTimeout(() => {
          this.isFilterChannel = true
          clearTimeout(time)
        }, 10)
      }
    },
    getAnalyticTotalInteractions (isFilterChannel) {
      if (isFilterChannel) {
        this.filterChannels(this.$t('analytic.totalBotInteractions'), false, true)
      } else {
        this.isFilterChannel = false
        const params = {
          botId: this.getterSelectedBot.idBot,
          ...this.datePickerData,
          context: this.context
        }
        this.actionAnalyticTotalInteractions(params)
          .then(response => {
            this.auxGraphicsFilter = response
            this.createdFilterChannels()
            this.filterChannels(this.$t('analytic.totalBotInteractions'), false, true)
          })
          .catch(() => {
            this.getError()
          })
        const time = setTimeout(() => {
          this.isFilterChannel = true
          clearTimeout(time)
        }, 10)
      }
    },
    getAnalyticLowAssertiveness () {
      const params = this.mountParams()
      params.pageCount = 20
      params.ordinationField = 'expression'
      params.ordinationType = 'asc'

      this.actionAnalyticLowAssertivenessExpressions(params)
        .then(response => {
          const legends = []
          const labels = []
          let isData = false
          response.labels[0].values.forEach(item => {
            if (item.includes('_')) {
              const dates = item.split('_')
              labels.push(`${moment(dates[0]).format('MM/YYYY')}`)
            } else {
              labels.push(moment(item).format(this.$getterFormatDate))
            }
          })
          response.series.forEach(item => {
            if (item.name === 'expressions_above_50_percent') {
              item.name = this.$t('analytic.greaterThan')
              legends.push(
                {
                  text: this.$t('analytic.totalExpressionsGreaterThan'),
                  value: item.total[0]
                }
              )
            } else {
              item.name = this.$t('analytic.lessThan')
              legends.push(
                {
                  text: this.$t('analytic.totalExpressionsLessThan'),
                  value: item.total[0]
                }
              )
            }
            if (!isData && item.total[0]) {
              isData = true
            }
          })
          if (isData) {
            this.mountedGraphics(response, [], true, { listLabels: labels })
            this.getSuccess()
          } else {
            this.notSeries = true
            this.newGraphics = false
            this.getWarning()
          }
        })
        .catch(() => {
          this.getError()
        })
    },
    getAnalyticUnknownExpressions () {
      const params = {
        botId: this.getterSelectedBot.idBot,
        ...this.datePickerData
      }

      this.actionAnalyticUnknownExpressions(params)
        .then(response => {
          const labels = []
          let isData = false
          response.labels[0].values.forEach(item => {
            if (item.includes('_')) {
              const dates = item.split('_')
              labels.push(`${moment(dates[0]).format('MM/YYYY')}`)
            } else {
              labels.push(moment(item).format(this.$getterFormatDate))
            }
          })
          response.series.forEach(item => {
            if (item.name === 'unknown_expressions_registered') {
              item.name = this.$t('analytic.unknownExpressions.unknownExpressionsRegistered')
            } else if (item.name === 'unknown_expressions_unregistered') {
              item.name = this.$t('analytic.unknownExpressions.unknownExpressionsUnregistered')
            }
            if (!isData && item.total[0]) {
              isData = true
            }
          })
          if (isData) {
            this.mountedGraphics(response, [], true, { listLabels: labels })
            this.getSuccess()
          } else {
            this.notSeries = true
            this.newGraphics = false
            this.getWarning()
          }
        })
        .catch(() => {
          this.getError()
        })
    },
    customLabelsSeries (response) {
      const labelsSeries = {
        listLabels: [],
        listSeries: []
      }
      response.series.sort((a, b) => {
        if (a.total[0] > b.total[0]) {
          return -1
        }
        if (a.total[0] < b.total[0]) {
          return 1
        }
        return 0
      })
      response.series.forEach(item => {
        labelsSeries.listLabels.push(item.name)
        labelsSeries.listSeries.push(item.total[0])
      })
      return labelsSeries
    },
    createdFilterChannels () {
      this.auxGraphicsFilter.series.forEach((item, index) => {
        this.graphicSelected.filter.push({ id: index + 2, name: item.name })
      })
    },
    filterChannels (legendTxt, showLegends = true, isTotal = false) {
      const labels = []
      let isData = false

      this.auxGraphicsFilter.labels[0].values.forEach(item => {
        if (item.includes('_')) {
          const dates = item.split('_')
          labels.push(`${moment(dates[0]).format('MM/YYYY')}`)
        } else {
          labels.push(moment(item).format(this.$getterFormatDate))
        }
      })
      let seriesChannel = []
      if (this.listMultSelected.includes(1)) {
        seriesChannel = this.auxGraphicsFilter.series
      } else {
        this.auxGraphicsFilter.series.forEach(item => {
          this.graphicSelected.filter.forEach(channel => {
            if (this.listMultSelected.includes(channel.id) && channel.name === item.name) {
              seriesChannel.push(item)
            }
          })
        })
      }
      let total = 0
      seriesChannel.forEach(item => {
        total += item.total[0]
        if (!isData && item.total[0]) {
          isData = true
        }
      })
      const legends = !showLegends ? [] : [
        {
          text: legendTxt,
          value: total
        }
      ]
      if (isData) {
        this.mountedGraphics({ series: seriesChannel, labels: this.auxGraphicsFilter.labels }, legends, isTotal, { listLabels: labels })
        const time = setTimeout(() => {
          this.getSuccess()
          clearTimeout(time)
        }, 100)
        this.notSeries = false
        this.newGraphics = true
      } else {
        this.notSeries = true
        this.newGraphics = false
        this.getWarning()
      }
    },
    mountedGraphics (response, legends, total, labelsSeries) {
      this.graphics = []
      let isBar = false
      this.graphicSelected.types.forEach(item => {
        const graphic = {
          type: '',
          width: 0,
          isTotal: total,
          horizontal: false,
          header: {
            title: this.$t(this.graphicSelected.titleDescription || this.graphicSelected.title)
          },
          series: [],
          labels: [],
          colors: this.chartColorConfig,
          legends: []
        }
        if (item === 'area') {
          graphic.type = response.labels[0].values[0].includes('_') ? 'bar' : item
          graphic.width = 900
          graphic.labels = labelsSeries ? labelsSeries.listLabels : response.labels[0].values
          response.series.forEach(item => {
            graphic.series.push({
              name: item.name,
              data: item.data[0].values
            })
          })
        } else if (item === 'bar') {
          isBar = true
          graphic.type = item
          graphic.horizontal = true
          graphic.width = 900
          graphic.labels = labelsSeries.listLabels
          graphic.series = [{
            data: labelsSeries.listSeries
          }]
        } else if (item === 'donut' && !isBar) {
          graphic.type = item
          graphic.width = 700
          response.series.forEach(item => {
            graphic.labels.push(item.name)
          })
          response.series.forEach(item => {
            graphic.series.push(item.total[0])
          })
          graphic.legends = legends
        } else {
          graphic.type = item
          graphic.width = 700
          graphic.labels = labelsSeries.listLabels
          graphic.series = labelsSeries ? labelsSeries.listSeries : response.series[this.listSelected].data[0].values
          graphic.legends = legends
        }
        this.graphics.push(graphic)
      })
    },
    getSuccess () {
      this.isLoading = false
      this.addToast({
        text: this.$t('analytic.toast.success'),
        type: 'success'
      })
    },
    getError () {
      this.graphics = []
      this.isLoading = false
      this.addToast({
        text: this.$t('analytic.toast.errorFetching'),
        type: 'danger'
      })
    },
    getWarning () {
      this.isLoading = false
      this.addToast({
        text: this.$t('analytic.toast.noInformationSelected'),
        type: 'warning'
      })
    },
    translateGraphics (graphics) {
      const graphicsTranslate = cloneDeep(graphics)
      graphicsTranslate.forEach(element => {
        element.title = this.$t(element.title)
      })
      return graphicsTranslate
    },
    translateFilter (filter) {
      const filterTranslate = cloneDeep(filter)
      filterTranslate.forEach(element => {
        element.name = this.$t(element.name)
      })
      return filterTranslate
    }
  }
}
