const DefaultCharts = [
  // {
  //   types: ['bar', 'donut'],
  //   dataType: 'conversations',
  //   title: 'analytic.conversations.title',
  //   description: 'analytic.conversations.description'
  // },
  {
    types: ['bar', 'donut'],
    dataType: 'incoming_events',
    title: 'analytic.incomingEvents.title',
    description: 'analytic.incomingEvents.description'
  },
  {
    types: ['bar', 'donut'],
    dataType: 'incoming_interactions_total',
    title: 'analytic.incomingInteractionsTotal.title',
    description: 'analytic.incomingInteractionsTotal.description'
  },
  {
    types: ['bar', 'donut'],
    dataType: 'incoming_messages',
    title: 'analytic.incomingMessages.title',
    description: 'analytic.incomingMessages.description'
  },
  // {
  //   types: ['bar', 'donut'],
  //   dataType: 'interactions_total',
  //   title: 'analytic.interactionsTotal.title',
  //   description: 'analytic.interactionsTotal.description'
  // },
  {
    types: ['bar', 'donut'],
    dataType: 'outgoing_attendance_replies',
    title: 'analytic.outgoingAttendanceReplies.title',
    description: 'analytic.outgoingAttendanceReplies.description'
  },
  {
    types: ['bar', 'donut'],
    dataType: 'outgoing_bot_replies',
    title: 'analytic.outgoingBotReplies.title',
    description: 'analytic.outgoingBotReplies.description'
  },
  {
    types: ['bar', 'donut'],
    dataType: 'outgoing_broadcast_messages',
    title: 'analytic.outgoingBroadcastMessages.title',
    description: 'analytic.outgoingBroadcastMessages.description'
  },
  {
    types: ['bar', 'donut'],
    dataType: 'outgoing_interactions_total',
    title: 'analytic.outgoingInteractionsTotal.title',
    description: 'analytic.outgoingInteractionsTotal.description'
  },
  {
    types: ['bar', 'donut'],
    dataType: 'transshipment',
    title: 'analytic.transshipment.title',
    description: 'analytic.transshipment.description'
  },
  // {
  //   types: ['bar', 'donut'],
  //   dataType: 'users',
  //   title: 'analytic.users.title',
  //   description: 'analytic.users.description'
  // },
  {
    types: ['bar', 'donut'],
    dataType: 'popular_intentions',
    title: 'analytic.popularIntentions.title',
    description: 'analytic.popularIntentions.description',
    default: 1,
    nameFilter: 'analytic.popularIntentions.nameFilter',
    filter: [
      {
        id: 1,
        name: 'Top 10'
      },
      {
        id: 2,
        name: 'Top 20'
      }
    ]
  },
  {
    types: ['bar', 'donut'],
    dataType: 'popular_expressions',
    title: 'analytic.popularExpressions.title',
    description: 'analytic.popularExpressions.description',
    default: 1,
    nameFilter: 'analytic.popularExpressions.nameFilter',
    filter: [
      {
        id: 1,
        name: 'Top 10'
      },
      {
        id: 2,
        name: 'Top 20'
      }
    ]
  },
  {
    types: ['area', 'donut'],
    dataType: 'low_assertiveness',
    title: 'analytic.lowAssertiveness.title',
    description: 'analytic.lowAssertiveness.description'
  },
  {
    types: ['area', 'donut'],
    dataType: 'users_interacted_bot',
    title: 'analytic.usersInteractedBot.title',
    description: 'analytic.usersInteractedBot.description',
    default: [1],
    nameFilter: 'analytic.usersInteractedBot.nameFilter',
    multSelected: true,
    filter: []
  },
  {
    types: ['area', 'donut'],
    dataType: 'bot_uptime',
    title: 'analytic.botUptime.title',
    description: 'analytic.botUptime.description'
  },
  {
    types: ['area', 'donut'],
    dataType: 'total_interactions',
    title: 'analytic.totalInteractionsGraphic.title',
    description: 'analytic.totalInteractionsGraphic.description',
    default: [1],
    nameFilter: 'analytic.totalInteractionsGraphic.nameFilter',
    multSelected: true,
    filter: [],
    filterIntentions: [
      {
        id: 1,
        name: 'analytic.totalInteractionsGraphic.optionAll'
      },
      {
        id: 2,
        name: 'analytic.totalInteractionsGraphic.optionBot'
      },
      {
        id: 3,
        name: 'analytic.totalInteractionsGraphic.optionAttendance'
      }
    ]
  },
  {
    types: ['area', 'donut'],
    dataType: 'unknown_expressions',
    title: 'analytic.unknownExpressions.title',
    description: 'analytic.unknownExpressions.description',
    titleDescription: 'analytic.unknownExpressions.titleDescription'
  }
]

export { DefaultCharts }
