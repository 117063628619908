import { mapGetters } from 'vuex'
import SvgLoader from '@/components/svg-loader/index.vue'

export default {
  name: 'ChannelSelector',

  components: { SvgLoader },

  // props: {
  //   channels: {
  //     type: Array,
  //     required: true,
  //     default: () => []
  //   }
  // },

  data: () => ({
    channels: [
      {
        name: 'PeopleChat',
        label: 'peoplechat',
        color: '#ea542d',
        enabled: true
      },
      {
        name: 'WhatsApp',
        label: 'whatsapp',
        color: '#24cd63',
        enabled: true
      },
      {
        name: 'Facebook',
        label: 'facebook',
        color: '#3a5895',
        enabled: true
      },
      {
        name: 'Workplace',
        label: 'workplace',
        color: '#282828',
        enabled: true
      },
      {
        name: 'Outros',
        label: 'default',
        color: '#282828',
        enabled: true
      }
    ]
  }),

  computed: {
    ...mapGetters(['getterSelectedBot']),

    cssVars () {
      const cssValues = {
        '--botColor': this.getterSelectedBot.botColor
      }
      return cssValues
    },

    enabledChannelsList () {
      const enabledChannels = this.channels.filter(channel => channel.enabled)
      const channelsList = enabledChannels.reduce((channels, channel) => channels.concat(channel.label), [])
      return channelsList
    }
  },

  methods: {
    toggleChannel (channelIndex) {
      this.channels[channelIndex].enabled = !this.channels[channelIndex].enabled
      this.$emit('change', this.enabledChannelsList)
    }
  }
}
